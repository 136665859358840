<template>
    <!-- 评价工种维护 -->
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <!-- 头部面包屑 -->
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">基础信息</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">评价工种维护</a>
                </span>
            </div>
            <!-- 主体区域 -->
            <div class="framePage-body">
                <!-- 搜索区域 -->
                <div class="operationControl" style="display: flex; flex-direction: column; align-items: flex-start">
                    <div class="searchbox">
                        <div title="培训工种" class="searchboxItem ">
                            <span class="itemLabel">培训工种:</span>
                            <el-input v-model="searchForm.occupationName" placeholder="请输入培训工种名称" type="text" clearable
                                size="small" />
                        </div>
                        <div class="searchboxItem">
                            <el-button type="primary" class="bgc-bv" round @click="getListData(1)">查询</el-button>
                            <el-button type="primary" class="bgc-bv" @click="newlyBuild(null)" round>新建工种</el-button>
                        </div>
                    </div>
                </div>
                <!-- 列表区域 -->
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table ref="multipleTable" :data="listData" :height="tableHeight" size="small"
                            tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader" stripe>
                            <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="50" />
                            <el-table-column prop="occupationName" label="培训工种" align="center" />
                            <el-table-column label="培训等级" align="center">
                                <template v-slot="{ row }">
                                    {{ $setDictionary("ET_TRAIN_LEVEL", row.trainLevel) }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="categoryName" label="培训类型" align="center" />
                            <el-table-column prop="createTime" label="创建日期" align="center" />
                            <el-table-column label="操作" align="center">
                                <template v-slot="{ row }">
                                    <el-button type="text" size="mini" style="padding: 0 5px;"
                                        @click="newlyBuild(row)">编辑</el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="ofShelf(row)">{{
                                        row.enable === false ? "上架" : "下架" }}</el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;"
                                        @click="copyData(row)">复制</el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;" :disabled="!row.showApplyFormConfigButton"
                                        @click="goUpApplicationForm(row)">{{ row.applyConfigId ? '编辑申请表' : '设置申请表'
                                        }}</el-button>
                                </template>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <!-- 分页器 -->
                <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange" />
            </div>
        </div>
        <!-- 复制 - 弹框 -->
        <el-dialog title="复制" :visible.sync="copyDatas.dialogVisible" width="500px" :destroy-on-close="true">
            <el-form ref="copyDatas" class="copyDatas" :model="copyDatas" :rules="copyDatasRules" label-width="100px"
                size="small" :before-close="cancelData">
                <el-form-item label="工种名称" prop="occupationName">
                    <el-input v-model="copyDatas.occupationName" placeholder="请输入工种名称"></el-input>
                </el-form-item>
                <el-form-item label="培训等级" prop="trainLevel">
                    <el-select v-model="copyDatas.trainLevel" placeholder="请选择培训等级" size="small" clearable>
                        <el-option v-for="item in trainLevelList" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" class="bgc-bv" size="small" @click="cancelData">取消</el-button>
                <el-button type="primary" class="bgc-bv" size="small" @click="saveData">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import { resetKeepAlive } from "@/utils/common";
import List from "@/mixins/List";
export default {
    name: "evaluationMaintenance",
    components: {
        Empty,
        PageNum
    },
    mixins: [List],
    data() {
        return {
            // 搜索表单
            searchForm: {
                occupationName: "",//培训工种名称
            },
            // 分页器数据
            pagination: {
                total: 0,//总条数
                size: 10,//每页几条数据
                current: 1//当前页
            },
            //表格数据
            listData: [],
            // 复制弹框 - 数据
            copyDatas: {
                dialogVisible: false, // 弹框状态
                occupationId: "", // 工种id
                occupationName: "", // 工种名称
                trainLevel: "", // 等级
            },
            // 复制弹框 - 数据 - 校验
            copyDatasRules: {
                occupationName: [
                    { required: true, message: "请输入工种名称", trigger: "blur" },
                ],
                trainLevel: [
                    { required: true, message: "请选择培训等级", trigger: "change" },
                ],
            },
            // 培训等级数据
            trainLevelList: [],
        }
    },
    methods: {
        // 序号
        indexMethod(index) {
            return (this.pagination.current - 1) * this.pagination.size + index + 1;
        },
        // 初始化
        initData() {
            // 获取列表数据
            this.getListData();
            // 获取培训等级
            this.getDictionaries();
        },
        // 获取列表数据
        getListData(current) {
            if (current) this.pagination.current = current;
            const { searchForm, pagination } = this;
            this.$post("/biz/ct/occupation/pageList", {
                occupationName: searchForm.occupationName.trim(),
                pageNum: pagination.current,
                pageSize: pagination.size
            }).then(res => {
                this.listData = res?.data?.list || [];
                pagination.total = res?.data?.total || 0;
            }).catch(e => {
                console.error("获取评价工种列表出错", e);
            });
        },
        // 新建或者编辑工种按钮
        newlyBuild(row) {
            if (!row) {
                this.$router.push({
                    path: '/web/institutionalManagement/basicInfo/evaluationAdd',
                })
            } else {
                const { occupationId, inUse } = row;
                if (inUse) {
                    this.$confirm('该工种已经被多次使用，如果修改则已报名学员的部分信息将会丢失，建议重新创建一个工种， 是否继续编辑？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        confirmButtonClass: "bgc-bv",
                    }).then(() => {
                        this.$router.push({
                            path: '/web/institutionalManagement/basicInfo/evaluationAdd',
                            query: { occupationId }
                        })
                    });
                } else {
                    this.$router.push({
                        path: '/web/institutionalManagement/basicInfo/evaluationAdd',
                        query: { occupationId }
                    })
                }
            }
        },
        // 下架
        ofShelf(row) {
            const { occupationId, enable } = row;
            const title = enable === true ? "下架" : "上架";
            this.$confirm(`确认${title}该工种?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                confirmButtonClass: "bgc-bv",
            }).then(() => {
                this.$post("/biz/ct/occupation/disable", {
                    occupationId
                }).then(() => {
                    this.getListData();
                    this.$message.success("操作成功");
                });
            });
        },
        // 清除认证结果
        clearValid(key) {
            this.$refs.dialogForm.clearValidate(key);
        },
        // 当前页改变
        currentChange(current) {
            this.pagination.current = current;
            this.getListData();
        },
        // 当前页数改变
        sizeChange(size) {
            this.pagination.size = size;
            this.getListData();
        },
        // 复制 - 打开弹框
        copyData(row) {
            this.copyDatas.occupationId = row.occupationId;
            this.copyDatas.occupationName = row.occupationName;
            this.copyDatas.trainLevel = row.trainLevel;
            this.copyDatas.dialogVisible = true;
        },
        // 获取培训等级字典数据
        getDictionaries() {
            const trainLevleData = this.$setDictionary("ET_TRAIN_LEVEL", "list");
            Object.keys(trainLevleData).forEach(item => {
                this.trainLevelList.push({
                    label: trainLevleData[item],
                    value: item
                });
            });
        },
        // 复制 - 关闭弹框
        cancelData() {
            this.copyDatas = this.$options.data().copyDatas;
        },
        // 复制 - 弹框确认
        saveData() {
            this.$refs['copyDatas'].validate((valid) => {
                if (valid) {
                    this.$post("/biz/apply_template/ct-apply-form-config/copy", {
                        occupationId: this.copyDatas.occupationId,
                        occupationName: this.copyDatas.occupationName,
                        trainLevel: this.copyDatas.trainLevel,
                    }, 3000, true, 6).then(res => {
                        this.$message.success("复制成功");
                        this.cancelData();
                        this.initData();
                    }).catch(e => {
                        console.error("错误", e);
                    });
                }
            });
        },
        // 设置申请表
        goUpApplicationForm(row) {
            this.$router.push({
                path: '/web/institutionalManagement/basicInfo/setUpApplicationForm',
                query: {
                    occupationId: row.occupationId,//工种id
                    occupationName: row.occupationName,// 培训工种名称
                    trainLevel: row.trainLevel,// 培训等级
                    applyConfigId: row.applyConfigId, // 有这个id就是编辑过得(没啥用，就一个标识)
                }
            })
        },
        // 设置表格高度
        getTableHeight(opDom = false, page = true) {
            let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
            if (opDom) tHeight -= 40 + 0.675 * 16 + 1;
            if (page) tHeight -= 32;
            this.tableHeight = tHeight;
        },
    },

    created() {
        this.initData();
    },
    beforeRouteLeave: resetKeepAlive,
    beforeRouteEnter(to, from, next) {
        next(vm => {
            // 通过 `vm` 访问组件实例
            if (from.path == '/web/institutionalManagement/basicInfo/evaluationAdd') {
                vm.getListData()
            }
        })
    }
}
</script>

<style lang="less" scoped>
.templateList {
    .el-form-item-val {
        width: 50%;
        max-height: 10rem;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        padding-left: 20px;
        overflow-y: auto;

        .el-checkbox-group {
            display: flex;
            flex-direction: column;
        }
    }

    .customBtn:nth-child(2) {
        background-color: #5C6BE8;
        border: none;

        &:hover {
            background-color: #6875ee;
        }
    }
}
</style>